$theme-colors: (
    "primary": blue,
    "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

body {

}
.footer {
    margin-top:3rem;
}
.navbar {
    border-bottom:1px solid #eee;
}

.main-logo{
    margin-top: 50px;
    margin-bottom: 50px;
}

.intro-text{
    font-size: 21px;
    strong{
        margin-bottom: 20px;
        display: block;
    }
}

.main-banner-img{
    margin-bottom: 40px;
}

.terms-info{
    font-size: 12px;
    a{
        color: #000;
        text-decoration: none;
    }
}


.contact-info{
    line-height: 25px;
    margin-top: 40px;
    img{
        width: 40px;
        margin-left: -4px;
        margin-top: 15px;
    }

    a{
        color: #23408E;
    }
}

.secondary-description{
    margin-top: 40px;
    small{
        font-size: 12px;
        color: grey;
    }
}

footer{
    padding: 15px;
    background-color: #DDDDDD;
    .jomo-logo{
        text-align: right;
    }
    .terms-info{
        margin-top: 10px;
    }

    p{
        margin: 0;
    }


    img{
        width: 35px;
    }
}

.logo{
    width: 100%;
    text-align: right;
    img{
        width: 200px;
    }
}

.general-content{
    padding: 0 0 100px;

    font-size: 14px;

    h1{
        font-size: 1.8rem;
        margin-bottom: 30px;
    }

    h2{
        font-size: 1.3rem;
    }

    p{
        font-size: 14px;
    }
}